















































































































































































































import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator"
import NewTask from "@/components/NewTask.vue"
import TaskEdit from "@/components/TaskEdit.vue"
import RepeatEdit from "@/components/RepeatEdit.vue"
import TaskNote from "@/components/TaskNote.vue"
import DateUtil from "@/util/DateUtil"
import Task from "@/lib/Task"
import Util from "@/util/Util"

@Component({
  components: {
    NewTask,
    TaskEdit,
    RepeatEdit,
    TaskNote,
  },
})
export default class TaskRow extends Vue {
  get displayedTaskCal(): boolean {
    return this.displayedTaskCal_
  }
  set displayedTaskCal(value: boolean) {
    this.displayedTaskCal_ = value
  }
  get targetDate(): string {
    return this.task_.date.toISOString().substr(0, 10)
  }

  set targetDate(value: string) {
    this.task_.date = new Date(value)
  }

  public get note(): string {
    // 1行目のみ返す
    const ret = this.task_.note.split("\n")
    if (ret.length === 0) {
      return ""
    }
    return ret[0]
  }

  @Prop() public task_!: Task
  @Prop() public index_!: number

  private isEdit_: boolean = false
  private editingRepeat_: boolean = false

  private displayedTaskCal_: boolean = false

  private targetDate_: Date = new Date()

  private noteDialog_: boolean = false

  @Emit("clickStartButtomEvent")
  // tslint:disable-next-line:no-empty
  public startTask(task: Task): void {
    task.isProcessing = true
  }

  @Emit("clickStopButtomEvent")
  // tslint:disable-next-line:no-empty
  public stopTask(task: Task): void {
    task.isProcessing = true
  }

  @Emit("clickDeleteButtomEvent")
  // tslint:disable-next-line:no-empty
  public deleteTask(task: Task): void {}

  @Emit("clickCopyButtomEvent")
  // tslint:disable-next-line:no-empty
  public copyTask(task: Task): void {}

  @Emit("endEditEvent")
  // tslint:disable-next-line:no-empty
  public endEdit(task: Task, index: number): void {}

  @Emit("changeTaskDateChangeEvent")
  // tslint:disable-next-line:no-empty
  public changeDate(task: Task): void {}

  @Emit("start-edit-task-name-event")
  // tslint:disable-next-line:no-empty
  public startEditTaskNameEvent(): void {}

  @Emit("end-edit-task-name-event")
  // tslint:disable-next-line:no-empty
  public endEditTaskNameEvent(): void {}

  public getTime(time: Date): string {
    let timeStr: string = ""
    if (time != undefined) {
      timeStr = DateUtil.getTimeString(time)
    }
    return timeStr
  }

  public startEdit(): void {
    this.isEdit_ = true
  }

  public endEditEvent(task: Task) {
    Util.assertIsDefined(task)
    Util.assertIsDefined(this.index_)

    this.isEdit_ = false
    this.endEdit(task, this.index_)
  }

  public endRepeatEditEvent(task: Task) {
    this.editingRepeat_ = false
    this.endEdit(task, this.index_)
  }

  public startEditTaskName() {
    this.startEditTaskNameEvent()
  }

  public endEditTaskName() {
    this.endEditTaskNameEvent()
  }

  /**
   * タスクの日付を変更
   */
  public selectDate(): void {
    this.displayedTaskCal = false
    this.changeDate(this.task_)
  }

  get topRowLayoutAttributes(): {} {
    // 画面サイズによって入力ボックスを横に並べるか縦に並べるか切り替える
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return { column: true }
      case "sm":
        return { column: true }
      case "md":
        return { row: true }
      case "lg":
        return { row: true }
      case "xl":
        return { row: true }
      default:
        return { row: true }
    }
  }

  private get multiLine(): boolean {
    // 画面サイズによってツールバーとのマージンを変更
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return true
      case "sm":
        return false
      case "md":
        return false
      case "lg":
        return false
      case "xl":
        return false
      default:
        return false
    }
  }

  get rowColor(): {} {
    if (this.task_.estimateSeparateStart === true) {
      return { color: "secondary lighten-1" }
    } else if (this.task_.estimateSeparateEnd === true) {
      return { color: "secondary" }
    } else {
      return { color: "white" }
    }
  }
}
