











































import { Component, Vue, Emit } from "vue-property-decorator"
import firebase from "firebase"

@Component
export default class Header extends Vue {
  @Emit("clickjumpToNextTaskButtomEvent")
  // tslint:disable-next-line:no-empty
  private junpToNextTask(): void {}

  private logout(): void {
    firebase.auth().signOut().then(() => {
      this.$router.push("/login")
    })
  }

  private jumpToSectionSetting(): void {
    this.$router.push("/sectionlist")
  }

  private jumpToTaskList(): void {
    this.$router.push("/tasklist")
  }

  private jumpToHelp(): void {
    window.open("https://a-tak.github.io/taskclear/", "newtab")
  }
}
