


































import { Component, Vue, Prop, Emit } from "vue-property-decorator"

@Component
export default class Note extends Vue {

  @Prop() public note_!: string

  private get note(): string {
    const text = this.note_
    return text
  }

  private editNote_: string = ""

  @Emit("endEditEvent")
  // tslint:disable-next-line:no-empty
  private endEdit(note: string): void {}

  @Emit("start-edit-task-name-event")
  // tslint:disable-next-line:no-empty
  private startEditTaskName(): void {}

  @Emit("end-edit-task-name-event")
  // tslint:disable-next-line:no-empty
  private endEditTaskName(): void {}

  @Emit("close-dialog-event")
  // tslint:disable-next-line:no-empty
  private closeDialog(): void {}

  private created(): void {
    // 編集用オブジェクト作成
    this.editNote_ = this.note_
  }

  private save(): void {
    // 編集終了イベント発生
    this.endEdit(this.editNote_)
    this.endEditTaskName()
    this.closeDialog()
  }

  private cancel(): void {
    // 元に戻す
    this.editNote_ = this.note_
    this.closeDialog()
  }
}
