import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=3f5db600&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5db600",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VCard,VCardText,VFooter})
