













































import { Component, Vue, Prop, Emit } from "vue-property-decorator"
import Section from "@/lib/Section"
import DateUtil from "@/util/DateUtil"

@Component
export default class SectionRow extends Vue {
// !はundefinedやnullにならないことを示すもの
  @Prop() public section_!: Section
  @Prop() public index_!: number

  private startTime_: string = ""

  @Emit("clickDeleteButtomEvent")
  // tslint:disable-next-line:no-empty
  public deleteSection(section: Section): void {}
  @Emit("changeEvent")
  // tslint:disable-next-line:no-empty
  public changeSection(section: Section): void {}

  @Emit("setFirstSectionEvent")
  // tslint:disable-next-line:no-empty
  private setFirtstSection(section: Section): void {}

  private created() {
    this.startTime_ = DateUtil.get4digitTime(this.section_.startTime)
  }

  private changeStartTime(): void {
    if (this.startTime_.trim() !== "" ) {
      // getDateObjectがbaseDateを求めるのでややこしいことになってる
      let baseDate: Date = new Date()
      const sectionDate: Date | undefined = this.section_.startTime
      if (sectionDate !== undefined) {
        baseDate = sectionDate
      }
      this.section_.startTime = DateUtil.getDateObject(baseDate , this.startTime_)
    }
    this.changeSection(this.section_)
  }

  // 算出プロパティーでオブジェクトを返すと属性を展開してくれる
  get layoutAttributes(): {} {
      // 画面サイズによって入力ボックスを横に並べるか縦に並べるか切り替える
      switch (this.$vuetify.breakpoint.name) {
          case "xs": return {column: true}
          case "sm": return {column: true}
          case "md": return {row: true}
          case "lg": return {row: true}
          case "xl": return {row: true}
          default  : return {row: true}
      }
  }
}
